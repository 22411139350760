  @media only screen and (max-width: 600px) {
    .swal-modal {
        width: calc(100% - 60px)
      }
  }

  .swal-button {
    background-color: #646569;
  }

  .swal-button:hover {
    background-color: #434346 !important;
  }